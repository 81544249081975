@import '../../../assets/style/colors';
@import '../../../assets/style/breakpoints';

.profile-my-candidates-page {
  height: 100vh;

  @media (max-width: $breakpoint-md) {
    height: var(--doc-height);
    padding: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  &__content {
    flex-grow: 1;
    width: 100%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: $breakpoint-md) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      flex: 1 0 auto;
      width: 100%;
      height: calc(100% - 146px - 60px);
      padding: 0 20px;
    }
    @media (max-width: $breakpoint-sm) {
      padding: 0 15px;
    }
  }

  &__title {
    display: none;

    @media (max-width: $breakpoint-md) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      background: $color-white;
      color: $color-primary-dark;
      font-size: 14px;
      line-height: 100%;
      font-weight: 700;
      padding: 0 20px;
      text-transform: uppercase;
      text-align: center;
      width: 100%;
      height: 46px;
      border-bottom: 1px solid $color-grey-lightest;
    }
  }

  &__bottom-section {
    @media (max-width: $breakpoint-md) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      background: $color-grey-lightest;
      color: $color-primary-dark;
      font-size: 13px;
      line-height: 100%;
      font-weight: 700;
      text-transform: uppercase;
      width: 100%;
      height: 60px;
    }
  }

  &__back-button {
    @media (max-width: $breakpoint-md) {
      left: 20px;
      bottom: 15px;
    }
    @media (max-width: $breakpoint-sm) {
      left: 15px;
    }
  }
}
