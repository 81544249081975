@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "./fonts";
@import "./colors";
@import "./breakpoints";

:root {
  --doc-height: 100%;
 }

* {
  outline: none;

  font-family: $font-montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  box-sizing: border-box;
  line-height: 120%;
}

html {
  overflow: hidden;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;

  @media (max-width: $breakpoint-md) {
    height: var(--doc-height);
  }

  &::-webkit-scrollbar-track {
    background-color: $color-white;
  }

  &::-webkit-scrollbar {
    width: 0.56vmin;
    background-color: $color-grey-light;

    @media (max-width: $breakpoint-md) {
      width: 5px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: $color-grey-light;
    border-radius: 0.93vmin;

    @media (max-width: $breakpoint-md) {
      width: 10px;
    }
  }
}

p,
h1,
h2,
h3,
h4,
h5 {
  margin: unset;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}
