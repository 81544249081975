@import "../../../../../assets/style/colors";
@import "../../../../../assets/style/fonts";

.to-top-button {
  display: flex;
  justify-content: center;
  align-self: center;
  height: 4.54vmin;
  width: 5.21vmin;
  opacity: 1;
  transition-duration: 0.3s;

  &:hover {
    cursor: pointer;
    opacity: 0.85;
    transition-duration: 0.3s;
  }

  &.-disabled {
    pointer-events: none;
  }

  &__label {
    width: 100%;
    height: 100%;
    background-image: url('/assets/images/icons/icon-to-top.svg');
    background-repeat: no-repeat;
    background-size: cover;
  }
}