@import "../../../assets/style/colors";
@import "../../../assets/style/breakpoints";

.error-404-page {
  height: 100vh;

  @media (max-width: $breakpoint-md) {
    padding: 50px 20px 0;
  }
  @media (max-width: $breakpoint-sm) {
    padding: 50px 15px 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__language-select-menu {
    position: fixed;
  }

  &__title {
    color: $new-color-primary;
    font-size: 12.95vmin;
    line-height: 100%;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 3vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 110px;
      margin-bottom: 30px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 80px;
      margin-bottom: 25px;
    }
  }

  &__description {
    font-size: 1.85vmin;
    line-height: 3.43vmin;
    font-weight: 500;
    text-align: center;
    margin-bottom: 4.8vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 16px;
      line-height: 120%;
      margin-bottom: 30px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 13px;
      line-height: 120%;
      margin-bottom: 25px;
    }
  }

  &__button {
    min-width: 18.8vmin;

    @media (max-width: $breakpoint-md) {
      min-width: 200px;
    }
  }
}
