@import "../../../assets/style/colors";
@import "../../../assets/style/breakpoints";

.main-category-page {
  height: 100vh;

  @media (max-width: $breakpoint-md) {
    height: var(--doc-height);
    padding: 0;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;

    @media (max-width: $breakpoint-md) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 16.02vmin;
    padding-right: 9.26vmin;
    width: 100%;

    @media (max-width: $breakpoint-lg) {
      padding: 2.78vmin;
    }
    @media (max-width: $breakpoint-md) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      flex: 1 0 auto;
      width: 100%;
      height: calc(100% - 147px - 63px);
      margin: 0;
      padding: 20px;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    @media (max-width: $breakpoint-sm) {
      padding: 15px;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: $color-white;

    @media (max-width: $breakpoint-md) {
      display: none;
    }
  }

  &__title {
    width: 69.08vmin;
    font-size: 8.98vmin;
    line-height: 100%;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 2.22vmin;

    @media (max-width: $breakpoint-lg) {
      font-size: 6.01vmin;
      width: 80%;
    }
  }

  &__descriptions {
    text-align: left;
    margin-bottom: 4.8vmin;
    max-width: 55vmin;

    @media (max-width: $breakpoint-lg) {
      width: 80%;
    }
  }

  &__description {
    font-size: 1.85vmin;
    line-height: 165%;
    font-weight: 600;
    margin-bottom: 2.85vmin;
  }

  &__mobile-title {
    display: none;

    @media (max-width: $breakpoint-md) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      background: $color-white;
      color: $new-color-primary;
      font-size: 14px;
      line-height: 100%;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      width: 100%;
      height: 47px;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: $breakpoint-md) {
      width: 100%;
      height: auto;
      flex: 1 0 auto;
    }

    &-button-container {
      @media (max-width: $breakpoint-md) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 75%;
      }
      @media (max-width: $breakpoint-sm) {
        width: 100%;
      }
    }

    &-button {
      @media (max-width: $breakpoint-md) {
        margin-bottom: 0;
      }

      &:not(:last-child) {
        margin-bottom: 1.02vmin;

        @media (max-width: $breakpoint-md) {
          margin-bottom: 7px;
        }
      }
    }
  }

  &__bottom-section {
    @media (max-width: $breakpoint-md) {
      flex-shrink: 0;
      position: relative;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: $color-white;
      width: 100%;
      height: 63px;
      min-height: 63px;
    }
  }

  &__back-button {
    @media (max-width: $breakpoint-md) {
      position: absolute;
      left: 20px;
      bottom: 15px;
      height: 33px;
      border-width: 1px;
    }
    @media (max-width: $breakpoint-sm) {
      left: 15px;
    }
  }
}
