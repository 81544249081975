@import "../../../assets/style/colors";
@import "../../../assets/style/breakpoints";

.candidates-search-page {
  height: 100vh;

  @media (max-width: $breakpoint-md) {
    height: var(--doc-height);
    padding: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $new-color-primary;
    height: 18.52vmin;
    min-height: 18.52vmin;
    width: 100%;
    box-shadow: 0 0.28vmin 0.56vmin #00000029;

    @media (max-width: $breakpoint-md) {
      display: none;
    }
  }

  &__mobile-title {
    display: none;

    @media (max-width: $breakpoint-md) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      background: $color-white;
      color: $color-primary-dark;
      font-size: 19px;
      line-height: 100%;
      font-weight: 800;
      padding: 0 20px;
      text-transform: uppercase;
      text-align: center;
      width: 100%;
      height: 46px;
      border-bottom: 1px solid $color-grey-lightest;
    }
  }

  &__title {
    text-align: center;
    font-size: 5.93vmin;
    text-transform: uppercase;
    text-align: center;

    &-part-1,
    &-space {
      color: $color-white;
    }

    &-part-2 {
      color: $color-white;
    }
  }

  &__content {
    flex-grow: 1;
    width: 100%;
    padding: 4.72vmin 9.90vmin 1.85vmin;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: auto;
    transition-duration: 0.8s;
    scroll-behavior: smooth; 

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: $breakpoint-md) {
      padding: 30px 50px;
    }
  }

  &__footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: $color-grey-lightest;
    height: 14.26vmin;
    min-height: 14.26vmin;
    width: 100%;

    @media (max-width: $breakpoint-md) {
      height: 96px;
      min-height: 96px;
    }
  }

  &__button-container {
    position: relative;
  }

  &__button-results {
    padding: 0 3.70vmin;

    @media (max-width: $breakpoint-md) {
      padding: 0 18px;
    }
  }

  &__reset {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(calc(3.06vmin + 100%), -50%);
    font-size: 1.48vmin;
    text-transform: uppercase;
    text-decoration: underline;
    margin-bottom: 4.07vmin;
    transition-duration: 0.3s;

    @media (max-width: $breakpoint-md) {
      display: none;
    }

    &:hover {
      cursor: pointer;
      color: $color-secondary;
      transition-duration: 0.3s;
    }
  }
}