@import "../../../../../assets/style/colors";
@import "../../../../../assets/style/breakpoints";

.button36 {
  color: $color-white;
  background-color: $new-color-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.15vmin;
  font-size: 0.93vmin;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0 2.69vmin;
  border: none;
  border-radius: 2.87vmin;
  opacity: 1;
  transition-duration: 0.3s;

  @media (max-width: $breakpoint-md) {
    height: 34px;
    font-size: 10px;
    padding: 0 29px;
    border-radius: 31px;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.85;
    transition-duration: 0.3s;
  }

  &.-primary-dark {
    background-color: $color-primary-dark;
  }

  &.-secondary {
    background-color: $color-secondary;
  }

  &.-new-primary {
    background-color: $new-color-primary;
  }

  &.-new-primary-light {
    background-color: $new-color-primary-light;
  }

  &.-new-secondary {
    background-color: $new-color-secondary;
  }

  &.-new-tertiary {
    background-color: $new-color-tertiary;
  }
}
