@import "../../../../../assets/style/colors";

.submenu-button {
  color: $color-white;
  background-color: $new-color-primary;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 13.33vmin;
  height: 2.87vmin;
  font-size: 1.20vmin;
  line-height: 1.48vmin;
  text-transform: uppercase;
  padding-top: 0.83vmin;
  border: none;
  border-top-left-radius: 2.31vmin;
  border-top-right-radius: 2.31vmin;
  opacity: 1;
  transition-duration: 0.3s;

  &:hover {
    cursor: pointer;
    opacity: 0.85;
    transition-duration: 0.3s;
  }

  &.-primary {
    background-color: $color-primary;
  }

  &.-primary-dark {
    background-color: $color-primary-dark;
  }

  &.-primary-lighter {
    background-color: $color-primary-lighter;
  }

  &.-secondary {
    background-color: $color-secondary;
  }

  &.-active {
    background-color: $color-grey-light;
    opacity: 0.5;
    pointer-events: none;
  }

  &.-new-primary {
    background-color: $new-color-primary;
  }

  &.-new-primary-light {
    background-color: $new-color-primary-light;
  }

  &.-new-secondary {
    background-color: $new-color-secondary;
  }

  &.-new-tertiary {
    background-color: $new-color-tertiary;
  }

  &.-disabled {
    background-color: $color-grey-light;
    opacity: 0.5;
    pointer-events: none;
  }
}
