@import "../../../../assets/style/colors";
@import "../../../../assets/style/breakpoints";

.level-selector {
  display: flex;
  flex-direction: row;
  position: relative;

  &__item-container {
    display: flex;
    flex-direction: row;
  }

  &__empty-button-container {
    position: absolute;
    top: -0.56vmin;
    left: calc(100% + 2.31vmin);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-white-transparent-10;
    width: 2.78vmin;
    height: 2.78vmin;
    border-radius: 50%;
    transition-duration: 0.2s;

    @media (max-width: $breakpoint-md) {
      top: -6px;
      left: calc(100% + 24px);
      width: 29px;
      height: 29px;
    }
    @media (max-width: $breakpoint-sm) {
      top: -4px;
      left: calc(100% + 10px);
      width: 24px;
      height: 24px;
    }

    &:hover {
      cursor: pointer;
      transition-duration: 0.2s;
      background-color: $color-white;

      .level-selector__empty-button {
        transition-duration: 0.2s;
        color: $color-secondary;
      }
    }

    &.-invisible {
      display: none;
    }
  }

  &__empty-button {
    color: $color-white;
    width: 1.85vmin;
    height: 1.85vmin;
    transition-duration: 0.2s;

    @media (max-width: $breakpoint-md) {
      width: 20px;
      height: 20px;
    }
  }
}
