@import "../../../../assets/style/colors";
@import "../../../../assets/style/breakpoints";

.my-candidate-page-success {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: $breakpoint-lg) {
    padding: 2.78vmin;
  }
  @media (max-width: $breakpoint-md) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1 0 auto;
    width: 100%;
    height: calc(100% - 146px - 60px);
    padding: 0 20px;
  }
  @media (max-width: $breakpoint-sm) {
    padding: 0 15px;
  }

  &__avatar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 16.11vmin 0 7.41vmin;
    position: relative;

    @media (max-width: $breakpoint-md) {
      margin: 70px 0 36px;
    }
  }

  &__avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    width: 27.78vmin;
    height: 27.78vmin;
    border-radius: 50%;
    position: relative;

    @media (max-width: $breakpoint-md) {
      width: 190px;
      height: 190px;
    }

    &-image {
      color: $color-primary-dark;
      width: 15.28vmin;
      height: 15.28vmin;
      object-fit: cover;

      @media (max-width: $breakpoint-md) {
        width: 105px;
        height: 105px;
      }
    }
  }

  &__checkmark {
    position: absolute;
    top: 12.59vmin;
    right: -9.26vmin;
    width: 15.37vmin;
    height: 12.87vmin;
    background-image: url("/assets/images/icons/icon-checkmark-big.svg");
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: $breakpoint-md) {
      top: 100px;
      right: unset;
      left: 105px;
      width: 116px;
      height: 98px;
    }
  }

  &__title {
    color: $color-white;
    font-size: 5.93vmin;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 1.94vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 25px;
      width: 100%;
      overflow-wrap: break-word;
      margin-bottom: 12px;
    }

    &.-secondary {
      color: $color-secondary;
    }
  }

  &__description {
    color: $color-white;
    font-size: 1.48;
    margin-bottom: 3.43vmin;
    text-align: center;

    @media (max-width: $breakpoint-md) {
      font-size: 15px;
      line-height: 150%;
      margin-bottom: 40px;
    }
  }

  &__button {
    width: 18.80vmin;

    @media (max-width: $breakpoint-md) {
      flex-shrink: 0;
      width: 200px;
      margin-bottom: 20px;
    }
  }
}
