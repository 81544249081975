@import "../../../../assets/style/colors";
@import "../../../../assets/style/breakpoints";

.select-field {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 28.7vmin;

  @media (max-width: $breakpoint-md) {
    width: 300px;
  }

  &__top {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    .select-field__label {
      text-transform: none;
    }
  }

  &__label {
    font-style: normal;
    font-weight: normal;
    font-weight: 500;
    font-size: 1.48vmin;
    color: $color-black;
    text-transform: capitalize;
    margin-bottom: 0.93vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: 1.48vmin;
    color: $color-grey-lightest;
    max-width: 28.7vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 14px;
      max-width: 300px;
    }
  }

  &__input {
    flex-grow: 1;
    font-style: normal;
    font-weight: normal;
    font-size: 1.11vmin;
    color: $color-black;

    @media (max-width: $breakpoint-md) {
      font-size: 12px;
    }
  }

  &__caret,
  &__clear {
    font-size: 2.04vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 22px;
    }
  }

  &__error {
    font-style: normal;
    font-weight: normal;
    font-size: 1.48vmin;
    color: $color-red;
    margin-bottom: 0.93vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
}
