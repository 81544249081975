@import "../../../assets/style/colors";
@import "../../../assets/style/breakpoints";

.privacy-policy-page {
  height: 100vh;
  padding: 0 2.77vmin 22.22vmin;

  @media (max-width: $breakpoint-md) {
    padding: 65px 20px 75px;
  }
  @media (max-width: $breakpoint-sm) {
    padding: 50px 15px 75px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: 11.11vmin;

    @media (max-width: $breakpoint-md) {
      margin-top: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    overflow: auto;
    transition-duration: 0.8s;
    scroll-behavior: smooth; 

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__title {
    color: $new-color-primary;
    font-size: 4.63vmin;
    line-height: 100%;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 3vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 38px;
      letter-spacing: 2px;
      margin-bottom: 30px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 25px;
      letter-spacing: 1px;
      margin-bottom: 15px;
    }
  }

  &__subtitle {
    font-size: 2.31vmin;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    margin: 3.24vmin 0 2.31vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 22px;
      margin: 30px 0 20px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 18px;
      margin: 20px 0 10px;
    }
  }

  &__description {
    font-size: 1.85vmin;
    line-height: 3.43vmin;
    font-weight: 500;
    text-align: center;
    margin-bottom: 2.41vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 16px;
      line-height: 180%;
      margin-bottom: 20px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 13px;
      margin-bottom: 10px;
    }
  }

  &__button {
    min-width: 18.8vmin;
    min-height: 5.65vmin;
    margin-top: 3.06vmin;
    margin-bottom: 3.06vmin;

    @media (max-width: $breakpoint-md) {
      min-width: 200px;
      min-height: 50px;
      line-height: 180%;
      margin: 25px 0 25px;
    }

    @media (max-width: $breakpoint-md) {
      margin: 15px 0 10px;
    }
  }
}
