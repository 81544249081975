@import "../../../../../assets/style/colors";

.slick-previous-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  left: 1.61vmin;
  width: auto;
  height: auto;
  z-index: 1;

  &::before {
    display: none;
  }

  &:hover {
    cursor: pointer;

    .slick-previous-arrow__chevron {
      color: $color-grey-light;
      transition-duration: 0.2s;
    }
  }

  &__chevron {
    color: $color-grey-lighter;
    height: 7.13vmin;
    transform: scaleX(0.76);
    transition-duration: 0.2s;
  }
}
