@import "../../../../../assets/style/colors";

.close-button-big {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.58vmin;
  width: 4.58vmin;
  background: $color-white;
  border: 0.09vmin solid $color-grey-light;
  border-radius: 50%;
  overflow: hidden;
  transition-duration: 0.3s;

  &:hover {
    cursor: pointer;
    border: 0.09vmin solid $color-grey-lightest;
    background: $color-grey-lightest;
    transition-duration: 0.3s;
  }

  &__icon {
    font-size: 2.68vmin;

    path {
      fill: $color-grey-dark;
      transition-duration: 0.3s;
    }
  }
}