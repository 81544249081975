@import "../../../../../assets/style/colors";
@import "../../../../../assets/style/fonts";
@import "../../../../../assets/style/breakpoints";

.menu-button {
  display: flex;
  justify-content: center;
  align-self: center;
  background-color: $new-color-secondary;
  height: 8.06vmin;
  width: 28.61vmin;
  border-radius: 11.30vmin;
  overflow: hidden;
  transition-duration: 0.3s;

  @media (max-width: $breakpoint-md) {
    width: 100%;
    height: 68px;
    min-height: 68px;
    border-radius: 50px;
  }

  &:hover {
    cursor: pointer;
    background-color: $new-color-tertiary;
    transition-duration: 0.3s;
  }

  &.-disabled {
    pointer-events: none;
  }

  &.-tertiary {
    background-color: $new-color-tertiary;
  }

  &__label {
    color: $color-white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 22.96vmin;
    font-size: 1.76vmin;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;

    @media (max-width: $breakpoint-md) {
      max-width: 80%;
      font-size: 16px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 13px;
    }

    &.-disabled {
      color: $color-grey-dark;
    }
  }
}