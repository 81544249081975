@import "../../../assets/style/colors";
@import "../../../assets/style/breakpoints";

.resend-verify-mail-page {
  height: 100vh;
  padding: 0 2.77vmin;

  @media (max-width: $breakpoint-md) {
    padding: 65px 20px 75px;
  }
  @media (max-width: $breakpoint-sm) {
    padding: 50px 15px 75px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: 9.26vmin;

    @media (max-width: $breakpoint-md) {
      margin-top: 0;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 3vmin;

    @media (max-width: $breakpoint-md) {
      margin-bottom: 30px;
    }
    @media (max-width: $breakpoint-sm) {
      margin-bottom: 26px;
    }

    &-front {
      color: $color-secondary;
      font-size: 12.95vmin;
      line-height: 100%;
      font-weight: 900;
      text-transform: uppercase;

      @media (max-width: $breakpoint-md) {
        font-size: 60px;
        letter-spacing: 1px;
      }
      @media (max-width: $breakpoint-sm) {
        font-size: 43px;
      }
    }

    &-back {
      color: $new-color-primary;
      font-size: 12.95vmin;
      line-height: 100%;
      font-weight: 900;
      text-transform: uppercase;

      @media (max-width: $breakpoint-md) {
        font-size: 55px;
        letter-spacing: 1px;
      }
      @media (max-width: $breakpoint-sm) {
        font-size: 39px;
      }
    }
  }

  &__verify {
    font-size: 3.7vmin;
    line-height: 4.54vmin;
    font-weight: 700;
    text-align: center;
    letter-spacing: 0.19vmin;
    margin-bottom: 2vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 30px;
      line-height: 120%;
      letter-spacing: 1px;
      margin-bottom: 10px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 20px;
      margin-bottom: 8px;
    }
  }

  &__description {
    font-size: 1.85vmin;
    line-height: 3.43vmin;
    font-weight: 500;
    text-align: center;
    margin-bottom: 4.8vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 16px;
      line-height: 120%;
      margin-bottom: 15px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 13px;
      margin-bottom: 10px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &-input {
      width: 26.94vmin;
      margin-bottom: 1.2vmin;

      @media (max-width: $breakpoint-md) {
        width: 50%;
        margin-bottom: 10px;
      }
      @media (max-width: $breakpoint-sm) {
        width: 80%;
        margin-bottom: 6px;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3.06vmin;

    @media (max-width: $breakpoint-md) {
      margin-top: 24px;
    }
    @media (max-width: $breakpoint-sm) {
      margin-top: 20px;
    }
  }

  &__button {
    min-width: 18.8vmin;

    &.-send {
      @media (max-width: $breakpoint-md) {
        min-width: 200px;
      }
      @media (max-width: $breakpoint-sm) {
        min-width: 170px;
      }
    }
  
    &.-login,
    &.-register {
      @media (max-width: $breakpoint-md) {
        min-width: 190px;
      }
      @media (max-width: $breakpoint-sm) {
        min-width: 160px;
      }
    }

    &:not(:last-child) {
      margin-bottom: 0.93vmin;

      @media (max-width: $breakpoint-md) {
        margin-bottom: 13px;
      }
    }
  }
}
