@import '../../../assets/style/colors';
@import '../../../assets/style/breakpoints';

.profile-edit-form {
  border-top: 0.09vmin solid $color-grey-dark;
  padding: 0.93vmin 2.78vmin;

  @media (max-width: $breakpoint-md) {
    width: 100%;
    border-top: none;
    padding: 10px 0 40px;
  }

  &__input {
    width: 41.67vmin;
    margin-bottom: 1.39vmin;

    @media (max-width: $breakpoint-md) {
      width: 100%;
      margin-bottom: 15px;
    }
  }

  &__message {
    font-size: 1.39vmin;
    padding-top: 0.56vmin;
    margin-bottom: 0.93vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 16px;
      padding-top: 5px;
      margin-bottom: 10px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 13px;
    }
  }

  &__button {
    margin-top: 1.85vmin;
    padding: 0 2.78vmin;
    min-width: 12.96vmin;

    @media (max-width: $breakpoint-md) {
      width: 70%;
      min-width: 70%;
      max-width: 70%;
      margin: 30px auto 0;
      padding: 0;
    }
  }
}
