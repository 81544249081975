@import "../../../../assets/style/colors";
@import "../../../../assets/style/breakpoints";

.toggle-slider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

    @media (max-width: $breakpoint-md) {
      justify-content: flex-end;
    }

  &__container {
    position: relative;
    display: inline-block;
    width: 8.15vmin;
    height: 3.06vmin;
    border: 0.09vmin solid $color-black;
    border-radius: 3.15vmin;

    @media (max-width: $breakpoint-md) {
      width: 84px;
      height: 32px;
      border-width: 1px;
      border-radius: 30px;
    }
    @media (max-width: $breakpoint-sm) {
      width: 45px;
      height: 20px;
      border-width: 1px;
      border-radius: 30px;
    }
  }

  &__body {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $color-white;
    border-radius: 3.15vmin;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    cursor: pointer;

    @media (max-width: $breakpoint-md) {
      border-radius: 30px;
    }

    &:before {
      position: absolute;
      content: "";
      height: 2.22vmin;
      width: 2.22vmin;
      left: 0.37vmin;
      bottom: 0.37vmin;
      background-color: $color-grey-lightest;
      border-radius: 50%;
      -webkit-transition: 0.4s;
      transition: 0.4s;

      @media (max-width: $breakpoint-md) {
        height: 24px;
        width: 24px;
        left: 4px;
        bottom: 3.5px;
      }
      @media (max-width: $breakpoint-sm) {
        height: 14px;
        width: 14px;
        left: 2px;
        bottom: 2px;
      }
    }
  }

  &__title {
    font-size: 1.67vmin;
    line-height: 2.04vmin;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 1.30vmin;
    text-align: center;

    @media (max-width: $breakpoint-md) {
      font-size: 11px;
      line-height: 120%;
      margin-bottom: 14px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 10px;
      line-height: 120%;
      margin-bottom: 10px;
    }
  }

  &__checkbox {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked {
      & + .toggle-slider__body:before {
        -webkit-transform: translateX(5vmin);
        -ms-transform: translateX(5vmin);
        transform: translateX(5vmin);
        background-color: $color-secondary;

        @media (max-width: $breakpoint-md) {
          -webkit-transform: translateX(49px);
          -ms-transform: translateX(49px);
          transform: translateX(49px);
        }
        @media (max-width: $breakpoint-sm) {
          -webkit-transform: translateX(24px);
          -ms-transform: translateX(324px);
          transform: translateX(24px);
        }
      }
    }

    &:focus {
      & + .toggle-slider__body{
        box-shadow: 0 0 0.09vmin #2196F3;

        @media (max-width: $breakpoint-md) {
          box-shadow: 0 0 1px #2196F3;
        }
      }
    }
  }
}
