@import "../../../assets/style/breakpoints";

.language-select-menu {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 2.22vmin 2.22vmin 0 0;

  @media (max-width: $breakpoint-md) {
    justify-self: flex-start;
    align-self: flex-end;
    position: static;
    top: 50px;
    right: 15px;
    margin: 10px 10px 20px 0;
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__flag {
    width: 6.87vmin;
    height: 3.12vmin;
    overflow: hidden;
    border-radius: 2.81vmin;
    transition-duration: 0.3s;
    opacity: 1;

    @media (max-width: $breakpoint-md) {
      width: 77px;
      height: 35px;
      border-radius: 25px;
    }
    @media (max-width: $breakpoint-sm) {
      width: 55px;
      height: 25px;
      border-radius: 15px;
    }

    &:hover {
      cursor: pointer;
      transition-duration: 0.3s;
      opacity: 0.8;
    }

    &:not(:last-child) {
      margin-right: 1.52vmin;

      @media (max-width: $breakpoint-md) {
        margin-right: 10px;
      }
    }

    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
