@import "../../../assets/style/colors";

.candidate-card {
  display: flex;
  flex-direction: column;
  width: 30vmin;
  height: 57.87vmin;
  border: 0.09vmin solid $color-grey-lightest;
  padding: 3.15vmin;
  position: relative;

  &__avatar-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 2.96vmin;
  }

  &__avatar {
    width: 15.04vmin;
    height: 15.04vmin;
    overflow: hidden;

    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__avatar-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-primary-dark;
    width: 15.04vmin;
    height: 15.04vmin;
    border-radius: 50%;
    overflow: hidden;

    &-image {
      height: 8.37vmin;

      path {
        fill: $color-white;
      }
    }
  }

  &__profile-number {
    color: $color-black-light;
    font-size: 1.39vmin;
    margin-bottom: 0.93vmin;
  }

  &__profile-title {
    color: $new-color-primary;
    min-height: 4.17vmin;
    font-size: 1.67vmin;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1.39vmin;
    word-wrap: break-word;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }

  &__profile-description {
    font-size: 1.11vmin;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  }

  &__space {
    height: 1.39vmin;
    width: 100%;
    border-bottom: 0.09vmin solid $color-grey-lightest;
    margin-bottom: 1.20vmin;
  }

  &__tools {
    min-height: 4.54vmin;
    padding-bottom: 0.83vmin;
    border-bottom: 0.09vmin solid $color-grey-lightest;
    margin-bottom: 1.20vmin;
  }

  &__tool {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 1.76vmin;

    &-name {
      font-size: 1.11vmin;
      text-transform: uppercase;
      margin-right: 0.93vmin;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &-level-indicator {
      width: 0.93vmin;
      height: 0.93vmin;
      background-color: $color-secondary;
      border: 0.09vmin solid $color-secondary;
      border-radius: 50%;

      &:not(:last-child) {
        margin-right: 0.37vmin;
      }

      &.-empty {
        background-color: transparent;
      }
    }
  }

  &__skills {
    min-height: 6.86vmin;
    padding-bottom: 1.44vmin;
    border-bottom: 0.09vmin solid $color-grey-lightest;
    margin-bottom: 1.20vmin;
  }

  &__skill {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 1.76vmin;

    &-name {
      font-size: 1.11vmin;
      text-transform: uppercase;
      margin-right: 0.93vmin;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &-level-indicator {
      min-width: 0.93vmin;
      width: 0.93vmin;
      height: 0.93vmin;
      background-color: $color-secondary;
      border: 0.09vmin solid $color-secondary;
      border-radius: 50%;

      &:not(:last-child) {
        margin-right: 0.37vmin;
      }

      &.-empty {
        background-color: transparent;
      }
    }
  }

  &__languages {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__language {
    display: flex;
    width: 1.61vmin;
    height: 1.61vmin;
    border-radius: 50%;
    overflow: hidden;
    
    &:not(:last-child) {
      margin-right: 0.46vmin;
    }

    &-icon {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__button-container {
    position: absolute;
    right: 3.15vmin;
    bottom: 3.15vmin;
  }
}
