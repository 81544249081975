@import "../../../../assets/style/colors";
@import "../../../../assets/style/breakpoints";

.level-selector-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0.88vmin;
  transition-duration: 0.2s;

  @media (max-width: $breakpoint-md) {
    border-radius: 10px;
  }

  &:not(:last-child) {
    margin-right: 0.56vmin;

    @media (max-width: $breakpoint-md) {
      margin-right: 11px;
    }
    @media (max-width: $breakpoint-sm) {
      margin-right: 7px;
    }
  }

  &:hover {
    cursor: pointer;
    transition-duration: 0.2s;
    background-color: $color-white-transparent-10;
  }

  &__indicator {
    height: 1.67vmin;
    width: 1.67vmin;
    border: 0.09vmin solid $color-white;
    border-radius: 50%;
    margin-bottom: 0.65vmin;
    transition-duration: 0.2s;

    @media (max-width: $breakpoint-md) {
      height: 18px;
      width: 18px;
      border-width: 1px;
      margin-bottom: 7px;
    }
    @media (max-width: $breakpoint-sm) {
      height: 14px;
      width: 14px;
      border-width: 1px;
      margin-bottom: 7px;
    }

    &.-active {
      background-color: $color-secondary;
      border-color: $color-secondary;
      transition-duration: 0.2s;
    }
  }

  &__level {
    color: $color-white;
    font-size: 0.83vmin;
    font-weight: 500;

    @media (max-width: $breakpoint-md) {
      font-size: 9px;
    }
  }
}
