@import "../../../../../assets/style/colors";

.profile-page-edit {
  height: calc(100% - 9.26vmin);
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}
