@import "../../../../../assets/style/colors";
@import "../../../../../assets/style/fonts";
@import "../../../../../assets/style/breakpoints";

.menu-button-big {
  display: flex;
  flex-direction: row;
  background-color: $color-white;
  height: 17.41vmin;
  width: 37.87vmin;
  border-radius: 11.30vmin;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  @media (max-width: $breakpoint-md) {
    width: auto;
    height: auto;
    aspect-ratio: 335/170;
    border-radius: 200px;
    min-width: 200px;
  }
  @media (max-width: $breakpoint-sm) {
    width: auto;
    height: auto;
    aspect-ratio: 335/170;
    border-radius: 200px;
  }

  &:hover {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  &:hover & {
    &__text {
      background-color: $color-grey-lightest;
    }
  }

  &.-reversed {
    flex-direction: row-reverse;
  }

  &.-disabled {
    pointer-events: none;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 25.56vmin;
    height: 100%;
    padding: 2.04vmin 2.04vmin 2.04vmin 3.61vmin;
    text-align: right;

    @media (max-width: $breakpoint-md) {
      width: 68%;
      padding: 5.7% 4.3% 5.7% 8.3%;
    }

    &.-reversed {
      padding: 2.04vmin 3.61vmin 2.04vmin 2.04vmin;
      text-align: left;

      @media (max-width: $breakpoint-md) {
        padding: 5.7% 8.3% 5.7% 4.3%;
      }
    }

    &.-disabled {
      color: $color-grey-dark;
    }
  }

  &__title {
    font-family: $font-raleway;
    font-size: 2.59vmin;
    font-weight: 900;
    text-transform: uppercase;

    @media (max-width: $breakpoint-md) {
      font-size: calc((100vh - 100px - 97px - 40px - 30px) / 4 * 0.114);
    }
  }

  &__description {
    font-family: $font-raleway;
    font-size: 1.20vmin;
    text-transform: uppercase;

    @media (max-width: $breakpoint-md) {
      font-size: calc((100vh - 100px - 97px - 40px - 30px) / 4 * 0.076);
      line-height: 130%;
    }
  }

  &__image-container {
    width: 12.31vmin;
    height: 100%;

    @media (max-width: $breakpoint-md) {
      width: 32%;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}