@import "../../../assets/style/colors";
@import "../../../assets/style/breakpoints";

.my-candidate-list-item {
  display: flex;
  flex-direction: column;
  border-top: 0.09vmin solid $color-grey-darker;

  @media (max-width: $breakpoint-md) {
    width: 100%;
    border-top-width: 1px;
  }

  &:first-child {
    @media (max-width: $breakpoint-md) {
      border-top: none;
    }
  }

  &:last-child {
    border-bottom: 0.09vmin solid $color-grey-darker;

    @media (max-width: $breakpoint-md) {
      border-bottom-width: 1px;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 5.74vmin;
    position: relative;

    @media (max-width: $breakpoint-md) {
      height: 50px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__arrow-container {
    display: flex;
    width: 3.15vmin;
    padding-left: 0.28vmin;

    @media (max-width: $breakpoint-md) {
      width: 30px;
      padding-left: 5px;
    }
  }

  &__arrow {
    height: 1.48vmin;
    transition-duration: 0.2s;

    @media (max-width: $breakpoint-md) {
      height: 16px;
    }

    &.-downwards {
      transition-duration: 0.2s;
      transform: rotate(90deg);
    }
  }

  &__name {
    $color: $color-primary-darker;
    min-width: 20.37vmin;
    font-size: 1.67vmin;
    text-transform: uppercase;
    padding-right: 1.85vmin;

    @media (max-width: $breakpoint-md) {
      width: 60%;
      min-width: 60%;
      max-width: 60%;
      font-size: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 20px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 15px;
      padding-right: 10px;
    }
    @media (max-width: $breakpoint-xs) {
      font-size: 12px;
    }
  }

  &__created-at {
    $color: $color-primary-darker;
    font-size: 1.67vmin;
    text-transform: uppercase;

    @media (max-width: $breakpoint-md) {
      width: 30%;
      min-width: 30%;
      max-width: 30%;
      font-size: 18px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 15px;
    }
    @media (max-width: $breakpoint-xs) {
      font-size: 12px;
    }
  }

  &__delete-button-container {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  &__body {
    padding-top: 0;
    padding-bottom: 0;
    transform: scaleY(0);
    transform-origin: top;
    height: 0;
    opacity: 0;
    transition: 0s;

    &.-open {
      padding: 2.04vmin 0 2.04vmin 10.56vmin;
      transform: scaleY(1);
      height: auto;
      opacity: 1;
      transition:
        all 0.15s,
        padding 0s;

      @media (max-width: $breakpoint-md) {
        padding: 10px 10px 20px 40px;
      }
    }
  }

  &__section {
    $color: $color-primary-darker;
    font-size: 1.67vmin;
    line-height: 2.78vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 18px;
      line-height: 180%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 15px;
    }
    @media (max-width: $breakpoint-xs) {
      font-size: 12px;
    }

    &:not(:last-child) {
      margin-bottom: 4.17vmin;

      @media (max-width: $breakpoint-md) {
        margin-bottom: 20px;
      }
      @media (max-width: $breakpoint-sm) {
        margin-bottom: 15px;
      }
    }

    &-title,
    &-importance {
      font-weight: 600;
      text-transform: uppercase;
    }

    &-text {
      margin-bottom: 0.56vmin;

      @media (max-width: $breakpoint-md) {
        margin-bottom: 0;
      }
    }
  }
}
