@import "../../../assets/style/colors";
@import "../../../assets/style/breakpoints";

.dot-description-modal {
  background: $color-white;
  padding: 2.78vmin 2.78vmin 3.24vmin;
  border: 2px solid $new-color-primary;
  -webkit-box-shadow: 0 0.28vmin 0.65vmin rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0.28vmin 0.65vmin rgba(0, 0, 0, 0.3);
  box-shadow: 0 0.28vmin 0.65vmin rgba(0, 0, 0, 0.3);
  opacity: 0;
  display: none;
  flex-direction: column;

  @media (max-width: $breakpoint-md) {
    flex-direction: column;
    align-items: center;
    width: 70%;
    min-height: 40%;
    max-height: 70%;
    padding: 45px;
    border-width: 1px;
    -webkit-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);
  }
  @media (max-width: $breakpoint-sm) {
    width: 90%;
    min-height: 30%;
    max-height: 90%;
    padding: 30px;
  }

  &.react-transition {
    &-enter {
      display: flex;
      opacity: 0;
    }
  
    &-enter-active {
      display: flex;
      opacity: 1;
      transition: opacity 300ms;
    }

    &-enter-done {
      display: flex;
      opacity: 1;
    }
  
    &-exit {
      display: flex;
      opacity: 1;
    }
  
    &-exit-active {
      display: flex;
      opacity: 0;
      transition: opacity 300ms;
    }

    &-exit-done {
      display: none;
      opacity: 0;
    }
  }

  &__title {
    color: $color-primary-dark;
    font-size: 1.85vmin;
    font-weight: 800;
    text-transform: uppercase;
    white-space: nowrap;
    margin-bottom: 3.24vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 20px;
      margin-bottom: 35px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 18px;
      margin-bottom: 30px;
    }
  }

  &__content {
    @media (max-width: $breakpoint-md) {
      max-width: 100%;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2.31vmin;

    @media (max-width: $breakpoint-md) {
      margin-bottom: 20px;
      max-width: 100%;
    }

    &:last-child {
      margin-bottom: 3.52vmin;

      @media (max-width: $breakpoint-md) {
        margin-bottom: 30px;
      }
    }
  }

  &__indicator {
    margin-right: 1.48vmin;

    @media (max-width: $breakpoint-md) {
      margin-right: 15px;
    }
  }

  &__description {
    color: $color-grey-darker;
    font-size: 1.11vmin;
    font-weight: 500;
    white-space: nowrap;

    @media (max-width: $breakpoint-md) {
      font-size: 12px;
      white-space: normal;
    }
  }

  &__button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    @media (max-width: $breakpoint-md) {
      width: 100%;
      justify-content: center;
    }
  }

  &__button {
    width: 12.96vmin;

    @media (max-width: $breakpoint-md) {
      width: 45%;
      padding: 0 25px;
    }
    @media (max-width: $breakpoint-sm) {
      width: 60%;
      padding: 0 20px;
    }
  }
}
