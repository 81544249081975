@import "../../../../../assets/style/colors";
@import "../../../../../assets/style/breakpoints";

.logout-button {
  position: fixed;
  bottom: 2.22vmin;
  left: 2.22vmin;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.58vmin;
  width: 4.58vmin;
  border: 0.09vmin solid $color-grey-light;
  border-radius: 50%;
  overflow: hidden;
  transition-duration: 0.3s;

  @media (max-width: $breakpoint-md) {
    bottom: 10px;
    left: 20px;
    height: 33px;
    width: 33px;
    border-width: 1px;
  }
  @media (max-width: $breakpoint-sm) {
    left: 15px;
  }

  &:hover {
    cursor: pointer;
    border: 0.09vmin solid $color-grey-lightest;
    background-color: $color-grey-light;
    transition-duration: 0.3s;

    .logout-button__icon {
      path {
        fill: $color-grey-lightest;
        transition-duration: 0.3s;
      }
    }
  }

  &__icon {
    font-size: 2.22vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 15px;
    }

    path {
      fill: $color-grey-light;
      transition-duration: 0.3s;
    }
  }
}