@import "../../../../../assets/style/colors";

.slick-dots {
  bottom: 1.02vmin;

  &__list {
    margin: 0;
    padding: 0;

    li {
      width: 1.20vmin;
      height: 1.20vmin;
      margin: 0;
  
      &:not(:last-child) {
        margin-right: 0.84vmin;
      }
  
      button {
        &:before {
          background-color: transparent;
          color: transparent;
          width: 1.20vmin;
          height: 1.20vmin;
          border: 0.09vmin solid $color-grey-lightest;
          border-radius: 50%;
          opacity: 1;
        }
      }
  
      &.slick-active {
        button:before {
          background-color: $color-black;
          color: transparent;
          border-color: $color-black;
          opacity: 1;
        }
      }
    }
  }
}
