@import "../../../../assets/style/colors";
@import "../../../../assets/style/breakpoints";

.textarea-field {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 40.19vmin;

  @media (max-width: $breakpoint-md) {
    width: 80%;
  }

  &.column {
    display: flex;
    flex-flow: column nowrap;
    & .textarea-field__label {
      padding: 0 0 0.93vmin;

      @media (max-width: $breakpoint-md) {
        padding: 0 0 10px;
      }
    }
  }

  &__top {
    display: flex;
    flex-direction: column;
  }

  &__label,
  &__input {
    color: $color-black;
    font-style: normal;
    font-weight: normal;
    font-size: 1.11vmin;
    height: 21.02vmin;
    padding: 1.48vmin;
    outline: none;

    @media (max-width: $breakpoint-md) {
      font-size: 12px;
      height: 127px;
      padding: 10px;
    }
  }

  &__label {
    margin-bottom: 0.93vmin;

    @media (max-width: $breakpoint-md) {
      margin-bottom: 10px;
    }
  }

  &__input {
    flex-grow: 1;
    border: 0.09vmin solid $color-grey-lightest;
    box-sizing: border-box;
    background: $color-white;
    resize: none;
    -webkit-appearance: none;

    @media (max-width: $breakpoint-md) {
      border-width: 1px;
    }

    &_error {
      border-color: $color-red;
    }

    &::placeholder {
      color: $color-grey-dark;
      text-transform: uppercase;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: $color-white;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-grey;
      border-radius: 0.93vmin;

      @media (max-width: $breakpoint-md) {
        border-radius: 10px;
      }
    }
  }

  &__error {
    flex-basis: 100%;
    color: $color-red;
    height: 1.29vmin;
    font-size: 1.29vmin;
    margin: 0.93vmin 0 0 0.93vmin;

    @media (max-width: $breakpoint-md) {
      height: 14px;
      font-size: 14px;
      margin: 10px 0 0 10px;
    }
  }

  &__text {
    max-width: 40.19vmin;
    font-style: normal;
    font-weight: normal;
    font-size: 1.11vmin;
    color: $color-grey-dark;

    @media (max-width: $breakpoint-md) {
      max-width: 80%;
      font-size: 13px;
    }
  }
}

.error_message {
  font-size: 1.11vmin;
  color: #ff0000;
  margin-bottom: 0.93vmin;

  @media (max-width: $breakpoint-md) {
    
  }
}
