@import "../../../assets/style/colors";

.candidate-display {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  &__chevron {
    height: 7.13vmin;
    transform: scaleX(0.76);

    path {
      fill: $color-black;
      transition-duration: 0.3s;
    }

    &:hover {
      cursor: pointer;

      path {
        fill: $new-color-primary;
        transition-duration: 0.3s;
      }
    }

    &.-disabled {
      pointer-events: none;

      path {
        fill: $color-grey;
      }
    }
  }

  &__card-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  &__card {
    &:not(:last-child) {
      margin-right: 2.5vmin;
    }
  }
}
