@import "../../../../assets/style/colors";
@import "../../../../assets/style/breakpoints";

.input-text-field {
  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 1.90vmin;

    @media (max-width: $breakpoint-md) {
      min-height: 21px;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 1.11vmin;
    color: $color-black;
    text-transform: capitalize;
    margin-bottom: 0.55vmin;
    margin-right: 0.55vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 13px;
      margin-bottom: 5px;
      margin-right: 5px;
    }

    &-error {
      color: $color-red;
    }
  }

  &__error-text {
    font-style: normal;
    font-weight: normal;
    font-size: 1.11vmin;
    color: $color-red;
    margin-bottom: 0.55vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 13px;
      margin-bottom: 5px;
    }
  }

  &__input {
    width: 100%;
    border: 0.09vmin solid $color-black;
    box-sizing: border-box;
    border-radius: 1.85vmin;
    font-size: 1.11vmin;
    font-weight: 600;
    padding: 1.20vmin 1.75vmin;
    outline: none;

    @media (max-width: $breakpoint-md) {
      border-width: 1px;
      font-size: 13px;
      border-radius: 30px;
      padding: 12px 18px;
    }

    -webkit-appearance: none;

    &:focus {
      -webkit-appearance: none;
    }

    &-wrapper {
      position: relative;
      width: 100%;
    }

    &-show {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 1.39vmin;
      font-size: 2.04vmin;
      color: #324a5e;
      display: flex;
      align-items: center;

      @media (max-width: $breakpoint-md) {
        right: 15px;
        font-size: 15px;
      }
    }

    &::-webkit-input-placeholder {
      color: $color-black;
      font-size: 1.11vmin;
      font-weight: 600;
      text-transform: uppercase;

      @media (max-width: $breakpoint-md) {
        font-size: 13px;
        font-weight: 400;
      }
    }

    &::-moz-placeholder {
      color: $color-black;
      font-size: 1.11vmin;
      font-weight: 600;
      text-transform: uppercase;

      @media (max-width: $breakpoint-md) {
        font-size: 13px;
        font-weight: 400;
      }
    }

    &:-ms-input-placeholder {
      color: $color-black;
      font-size: 1.11vmin;
      font-weight: 600;
      text-transform: uppercase;

      @media (max-width: $breakpoint-md) {
        font-size: 13px;
        font-weight: 400;
      }
    }

    &:-moz-placeholder {
      color: $color-black;
      font-size: 1.11vmin;
      font-weight: 600;
      text-transform: uppercase;

      @media (max-width: $breakpoint-md) {
        font-size: 13px;
        font-weight: 400;
      }
    }

    &:placeholder-shown {
      color: $color-black;
      font-size: 1.11vmin;
      font-weight: 600;
      text-transform: uppercase;

      @media (max-width: $breakpoint-md) {
        font-size: 13px;
        font-weight: 400;
      }
    }

    &::placeholder {
      color: $color-black;
      font-size: 1.11vmin;
      font-weight: 600;
      text-transform: uppercase;

      @media (max-width: $breakpoint-md) {
        font-size: 13px;
        font-weight: 400;
      }
    }

    &-error {
      border-color: $color-red;

      &:focus {
        outline-color: $color-red;
      }
    }

    &-read_only {
      color: $color-grey-light;
    }
  }
}
