@import "../../../../../assets/style/colors";

.profile-page-saved-candidates {
  height: calc(100% - 9.26vmin);
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__fallback-message {
    font-size: 1.85vmin;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    padding: 17.13vmin 9.26vmin 0;
    margin-bottom: 0.74vmin;
    border-top: 0.09vmin solid $color-grey-dark;
  }
}
