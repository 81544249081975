@import "../../../../assets/style/colors";
@import "../../../../assets/style/breakpoints";

.my-candidate-page-start {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: $breakpoint-lg) {
    padding: 2.78vmin;
  }
  @media (max-width: $breakpoint-md) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1 0 auto;
    width: 100%;
    height: calc(100% - 146px - 60px);
    padding: 0 20px;
  }
  @media (max-width: $breakpoint-sm) {
    padding: 0 15px;
  }

  &__avatar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 16.11vmin 0 6.02vmin;
    position: relative;

    @media (max-width: $breakpoint-md) {
      margin: 70px 0 7px;
    }
  }

  &__avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-white;
    width: 27.78vmin;
    height: 27.78vmin;
    border-radius: 50%;
    overflow: hidden;

    @media (max-width: $breakpoint-md) {
      width: 190px;
      height: 190px;
    }

    &-image {
      color: $color-primary-dark;
      width: 15.28vmin;
      height: 15.28vmin;
      object-fit: cover;

      @media (max-width: $breakpoint-md) {
        width: 105px;
        height: 105px;
      }
    }
  }

  &__title {
    color: $color-white;
    font-size: 5.93vmin;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1.39vmin;
    text-align: center;

    @media (max-width: $breakpoint-md) {
      display: none;
    }

    &.-secondary {
      color: $color-secondary;
    }
  }

  &__description {
    color: $color-white;
    font-size: 2.04vmin;
    line-height: 3.24vmin;
    text-align: center;
    margin-bottom: 3.70vmin;

    @media (max-width: $breakpoint-md) {
      order: 3;
      font-size: 15px;
      line-height: 150%;
      margin-bottom: 40px;
    }
  }

  &__form {
    @media (max-width: $breakpoint-md) {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    &-input {
      margin-bottom: 2.87vmin;

      @media (max-width: $breakpoint-md) {
        width: 60%;
        margin-bottom: 20px;

        & .input-text-field__input {
          height: 55px;
          padding: 12px 24px;
        }
      }
      @media (max-width: $breakpoint-sm) {
        width: 80%;
      }
    }
  }

  &__button {
    width: 18.80vmin;

    @media (max-width: $breakpoint-md) {
      width: 200px;
      margin-bottom: 20px;
    }
  }
}
