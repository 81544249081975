@import '../../../assets/style/breakpoints';

.dont-show-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3.89vmin;

  @media (max-width: $breakpoint-md) {
    margin-top: 20px;
  }

  &__checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.85vmin;

    @media (max-width: $breakpoint-md) {
      margin-bottom: 20px;
    }
  }

  &__checkbox {
    &-text {
      font-size: 1.11vmin;
      text-transform: uppercase;

      @media (max-width: $breakpoint-md) {
        font-size: 12px;
      }
    }
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 5.09vmin;

    @media (max-width: $breakpoint-md) {
      height: 52px;
    }
  }

  &__button {
    min-width: 12.04vmin;
    padding: 0 4.17vmin;

    @media (max-width: $breakpoint-md) {
      min-width: 130px;
      padding: 0 44px;
    }
  }

  .checkbox__error {
    display: none;
  }
}
