@import "../../../assets/style/colors";
@import "../../../assets/style/breakpoints";

.level-indicator {
  display: flex;
  flex-direction: row;

  &__dot {
    min-width: 0.93vmin;
    width: 0.93vmin;
    height: 0.93vmin;
    background-color: $color-secondary;
    border: 0.18vmin solid $color-secondary;
    border-radius: 50%;

    @media (max-width: $breakpoint-md) {
      min-width: 10px;
      width: 10px;
      height: 10px;
      border-width: 1px;
    }

    &:not(:last-child) {
      margin-right: 0.37vmin;

      @media (max-width: $breakpoint-md) {
        margin-right: 4px;
      }
    }
  
    &.-empty {
      background-color: transparent;
    }
  
    &.-size-10 {
      min-width: 0.93vmin;
      width: 0.93vmin;
      height: 0.93vmin;

      @media (max-width: $breakpoint-md) {
        min-width: 10px;
        width: 10px;
        height: 10px;
      }
  
      &:not(:last-child) {
        margin-right: 0.37vmin;

        @media (max-width: $breakpoint-md) {
          margin-right: 4px;
        }
      }
    }
  
    &.-size-20 {
      min-width: 1.85vmin;
      width: 1.85vmin;
      height: 1.85vmin;

      @media (max-width: $breakpoint-md) {
        min-width: 15px;
        width: 15px;
        height: 15px;
      }
  
      &:not(:last-child) {
        margin-right: 1.20vmin;

        @media (max-width: $breakpoint-md) {
          margin-right: 7px;
        }
      }
    }
  }
}
