// ------------------ MAIN COLOR PALETTE ------------------ //

$color-primary: #2A5B97;
$color-primary-dark: #3B4661;
$color-primary-darker: #333B45;
$color-primary-light: #535E79;
$color-primary-lighter: #A4A9B5;
$color-primary-lightest: #F1F4F8;

$color-secondary: #62B7E7;
$secondary-lightest: #E9F5FC;

$color-tertiary: #E2B867;
$color-tertiary-dark: #D98703;

// --------------------- GREY COLORS --------------------- //

$color-grey: #9F9F9F;
$color-grey-light: #ACACAC;
$color-grey-lighter: #CECECE;
$color-grey-lightest: #E8E8E8;
$color-grey-dark: #707070;
$color-grey-darker: #464646;
$color-grey-darkest: #232323;

// --------------------- BASIC COLORS --------------------- //

$color-white: #FFFFFF;
$color-white-transparent-10: #FFFFFF1A;
$color-black: #000000;
$color-black-light: #101010;
$color-red: #FF0000;
$color-red-dark: #9E200B;
$color-green: #2DA808;

// --------------------- NEW IETD COLORS --------------------- //
$new-color-primary: #3F5C57;
$new-color-primary-light: #748985;
$new-color-secondary: #3C3C3C;
$new-color-tertiary: #DDA01F;
$new-color-grey-light: #F5F5F5;