@import "../../../assets/style/colors";
@import "../../../assets/style/breakpoints";

.candidate-by-case-page {
  height: 100vh;
  width: 100%;

  @media (max-width: $breakpoint-md) {
    height: var(--doc-height);
    padding: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__header {
    flex-shrink: 0;
    background-color: $new-color-primary;
    height: 18.52vmin;
    width: 100%;
    box-shadow: 0 0.28vmin 0.56vmin #00000029;
  }

  &__title {
    text-align: center;
    margin-top: 7.69vmin;
    font-size: 4.17vmin;
  }

  &__profile-number {
    color: $color-white;
    font-size: 4.17vmin;
    font-weight: 500;
    letter-spacing: 0.21vmin;
    text-transform: uppercase;
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    overflow: auto;
    transition-duration: 0.8s;
    scroll-behavior: smooth; 
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: $breakpoint-lg) {
      padding: 2.78vmin;
    }
    @media (max-width: $breakpoint-md) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      flex: 1 0 auto;
      width: 100%;
      height: calc(100% - 147px - 63px);
      margin: 47px 0 48px;
      padding: 0;
    }
  }

  &__chevron {
    position: fixed;
    top: 50vmin;
    height: 7.13vmin;
    transform: scaleX(0.76);

    @media (max-width: $breakpoint-md) {
      height: 50px;
    }

    path {
      fill: $color-black;
      transition-duration: 0.3s;
    }

    &:hover {
      cursor: pointer;

      path {
        fill: $new-color-primary;
        transition-duration: 0.3s;
      }
    }

    &.-left {
      left: 9.90vmin;

      @media (max-width: $breakpoint-md) {
        position: absolute;
        top: 120px;
        left: 15px;
        transform: translateY(-50%);
      }
    }

    &.-right {
      right: 9.90vmin;

      @media (max-width: $breakpoint-md) {
        position: absolute;
        top: 120px;
        right: 15px;
        transform: translateY(-50%);
      }
    }

    &.-disabled {
      pointer-events: none;

      path {
        fill: $color-grey;
      }
    }
  }

  &__candidate {
    width: 100%;
    height: 100%;
  }

  &__footer {
    flex-shrink: 0;
    background-color: $color-grey-lightest;
    height: 14.26vmin;
    width: 100%;
  }

  &__mobile-title {
    display: none;
    
    @media (max-width: $breakpoint-md) {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      z-index: 1;
      top: 100px;
      left: 0;
      right: 0;
      background: $color-white;
      color: $color-primary-dark;
      font-size: 14px;
      line-height: 100%;
      font-weight: 700;
      padding: 0 20px;
      text-transform: uppercase;
      text-align: center;
      height: 46px;
      border-bottom: 1px solid $color-grey-lightest;
    }
  }

  &__bottom-section {
    @media (max-width: $breakpoint-md) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background: $color-grey-lightest;
      color: $color-primary-dark;
      font-size: 13px;
      line-height: 100%;
      font-weight: 700;
      text-transform: uppercase;
      height: 60px;
    }
  }

  &__back-button {
    @media (max-width: $breakpoint-md) {
      left: 20px;
      bottom: 15px;
    }
    @media (max-width: $breakpoint-sm) {
      left: 15px;
    }
  }
}