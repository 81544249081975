@import "../../../assets/style/colors";

.loading-layer {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999999;

  &.-active {
    display: block;
    cursor: wait;
  }
}
