@import "../../../assets/style/colors";
@import "../../../assets/style/breakpoints";

.candidate-card-wide {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  min-height: 20.09vmin;
  height: 20.09vmin;
  max-height: 20.09vmin;
  border-top: 0.09vmin solid $color-grey-dark;
  position: relative;

  &:last-child {
    border-bottom: 0.09vmin solid $color-grey-dark;
  }

  &__avatar-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 3.02vmin 1vmin 0 0.83vmin;
  }

  &__avatar {
    width: 10.50vmin;
    height: 10.50vmin;
    overflow: hidden;

    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__avatar-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-primary-dark;
    width: 10.50vmin;
    height: 10.50vmin;
    border-radius: 50%;
    overflow: hidden;

    &-image {
      height: 5.84vmin;

      path {
        fill: $color-white;
      }
    }
  }

  &__description-container {
    display: flex;
    flex-direction: column;
    min-width: 36%;
    width: 36%;
    max-width: 36%;
    padding: 3.02vmin 1.4vmin 3.02vmin 0;

    @media (max-width: $breakpoint-xxl) {
      min-width: 30%;
      width: 30%;
      max-width: 30%;
    }
  }

  &__profile-title {
    font-size: 1.85vmin;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 0.74vmin;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__profile-number {
    color: $color-black-light;
    font-size: 1.67vmin;
    font-weight: 300;
    margin-bottom: 1.67vmin;
  }

  &__languages {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1.67vmin;
  }

  &__language {
    display: flex;
    width: 1.61vmin;
    height: 1.61vmin;
    border-radius: 50%;
    overflow: hidden;
    
    &:not(:last-child) {
      margin-right: 0.46vmin;
    }

    &-icon {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__profile-description {
    font-size: 1.11vmin;

    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
            line-clamp: 3; 
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__skills-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 25%;
    width: 25%;
    max-width: 25%;
    padding: 3.02vmin 1.4vmin 3.02vmin 0;
  }

  &__tools {
    padding-bottom: 0.83vmin;
    border-bottom: 0.09vmin solid $color-grey-lightest;
    margin-bottom: 1.20vmin;
  }

  &__tool {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 1.76vmin;

    &-name {
      font-size: 1.11vmin;
      text-transform: uppercase;
      margin-right: 0.93vmin;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &-level-indicator {
      min-width: 0.93vmin;
      width: 0.93vmin;
      height: 0.93vmin;
      background-color: $color-secondary;
      border: 0.09vmin solid $color-secondary;
      border-radius: 50%;

      &:not(:last-child) {
        margin-right: 0.37vmin;
      }

      &.-empty {
        background-color: transparent;
      }
    }
  }

  &__skill {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 1.76vmin;

    &-name {
      font-size: 1.11vmin;
      text-transform: uppercase;
      margin-right: 0.93vmin;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &-level-indicator {
      min-width: 0.93vmin;
      width: 0.93vmin;
      height: 0.93vmin;
      background-color: $color-secondary;
      border: 0.09vmin solid $color-secondary;
      border-radius: 50%;

      &:not(:last-child) {
        margin-right: 0.37vmin;
      }

      &.-empty {
        background-color: transparent;
      }
    }
  }

  &__button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    flex-shrink: 0;
    min-width: 165px;
    height: calc(100% - 2.87vmin);
    margin: 1.11vmin 0 1.76vmin;
  }

  &__close-button {
    margin-right: 0.74vmin;
  }

  &__main-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__button {
    &:not(:last-child) {
      margin-bottom: 0.46vmin;
    }
  }
}
