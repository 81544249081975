@import "../../../../../assets/style/colors";
@import "../../../../../assets/style/breakpoints";

.back-button {
  position: fixed;
  bottom: 6.20vmin;
  left: 10.28vmin;
  display: flex;
  justify-content: center;
  align-self: center;
  background-color: $new-color-secondary;
  height: 4.17vmin;
  width: 11.57vmin;
  border-radius: 11.30vmin;
  overflow: hidden;
  transition-duration: 0.3s;

  @media (max-width: $breakpoint-md) {
    bottom: 33px;
    left: 15px;
    height: 30px;
    width: 83px;
    border-radius: 20px;
  }
  @media (max-width: $breakpoint-xxs) {
    bottom: 36px;
    height: 24px;
    width: 60px;
  }

  &:hover {
    cursor: pointer;
    background-color: $new-color-tertiary;
    transition-duration: 0.3s;
  }

  &.-disabled {
    pointer-events: none;
  }

  &__label {
    color: $color-white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 22.96vmin;
    font-size: 1.76vmin;
    transform: scaleX(1.5);

    @media (max-width: $breakpoint-md) {
      font-size: 14px;
      max-width: none;
    }
    @media (max-width: $breakpoint-xxs) {
      transform: scale(1.2, 0.75);
    }

    &.-disabled {
      color: $color-grey-dark;
    }
  }
}