@import "../../../assets/style/colors";

.top-menu-layer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2.22vmin 2.22vmin 0;

  &__button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4.58vmin;
    width: 4.58vmin;
    border: 0.09vmin solid $color-grey-light;
    border-radius: 50%;
    overflow: hidden;
    transition-duration: 0.3s;

    &:hover {
      cursor: pointer;
      border: 0.09vmin solid $color-grey-lightest;
      background-color: $color-grey-light;
      transition-duration: 0.3s;

      .top-menu-layer__button {
        path {
          fill: $color-grey-lightest;
          transition-duration: 0.3s;
        }
      }
    }
  }

  &__button {
    font-size: 2.22vmin;

    path {
      fill: $color-grey-light;
      transition-duration: 0.3s;
    }
  }
}