@import "../../../assets/style/colors";
@import "../../../assets/style/breakpoints";

.faq-item {
  display: flex;
  flex-direction: column;
  padding: 2.78vmin 0.93vmin;
  border-top: 0.09vmin solid $color-grey-darker;

  @media (max-width: $breakpoint-md) {
    width: 100%;
    padding: 15px 10px;
    border-top-width: 1px;
  }

  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-bottom: 0.09vmin solid $color-grey-darker;

    @media (max-width: $breakpoint-md) {
      border-bottom-width: 1px;
    }
  }

  &__question {
    min-width: 20.37vmin;
    font-size: 1.39vmin;
    text-transform: uppercase;
    font-weight: 700;
    padding-bottom: 0.93vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 18px;
      line-height: 180%;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 15px;
    }
    @media (max-width: $breakpoint-xs) {
      font-size: 12px;
    }
  }

  &__answer {
    font-size: 1.39vmin;
    line-height: 2.31vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 16px;
      line-height: 180%;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 14px;
    }
    @media (max-width: $breakpoint-xs) {
      font-size: 12px;
    }
  }
}
