@import "../../../../assets/style/colors";

.case-page-desktop {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: calc(100% - 16.76vmin);
    height: calc(100% - 16.76vmin);
    max-height: calc(100% - 16.76vmin);
    padding: 13.61vmin 9.72vmin 6.02vmin;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0.88%;

    &-button {
      &:not(:last-child) {
        margin-bottom: 1.02vmin;
      }
    }
  }

  &__footer {
    background: $new-color-primary;
    min-height: 16.76vmin;
    height: 16.76vmin;
    max-height: 16.76vmin;
    position: relative;
  }

  &__submenu {
    position: absolute;
    bottom: 100%;
    left: 10.28vmin;
    display: flex;
    flex-direction: row;

    &-button {
      &:not(:last-child) {
        margin-right: 0.463vmin;
      }
    }
  }

  &__button-cases {
    position: fixed;
    bottom: 6.20vmin;
    left: 23.61vmin;
  }
}
