@import "../../../../assets/style/colors";
@import "../../../../assets/style/breakpoints";

.checkbox {
  &__container {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 1.11vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 16px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 13px;
    }

    & .checkbox__input:checked ~ .checkbox__checkmark {
      background: url("../../../../assets/images/icons/icon_checkmark_primary.svg")
        no-repeat 50%;
      &:hover {
        background: url("../../../../assets/images/icons/icon_checkmark_secondary.svg")
          no-repeat 50%;
      }
    }

    & .checkbox__input:checked ~ .checkbox__checkmark_disable {
      background: url("../../../../assets/images/icons/icon_checkmark_grey.svg") no-repeat
        50% !important;
    }
  }

  &__text_container {
    display: flex;
    flex-direction: column;
  }

  &__label {
    font-weight: normal;
    font-size: 1.11vmin;
    text-transform: uppercase;
    color: $color-black;
    margin-bottom: 0;

    @media (max-width: $breakpoint-md) {
      font-size: 16px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 13px;
    }
  }

  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: 1.11vmin;
    text-transform: uppercase;
    color: $color-black;
    margin-left: 0.65vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 16px;
      margin-left: 8px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 13px;
    }
  }

  &__checkmark,
  &__checkmark_disable {
    box-sizing: border-box;
    display: inline-block;
    border-radius: 1.02vmin;
    height: 2.04vmin;
    width: 2.04vmin;
    border: 0.09vmin solid $color-black;
    background-color: $color-white;
    margin-left: 1vmin;

    @media (max-width: $breakpoint-md) {
      height: 20px;
      width: 20px;
      border-width: 1px;
      border-radius: 10px;
      margin-left: 10px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 13px;
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__error {
    font-style: normal;
    font-weight: normal;
    font-size: 1.11vmin;
    color: $color-red;
    margin-bottom: 0.93vmin;

    @media (max-width: $breakpoint-md) {
      font-size: 16px;
      margin-bottom: 8px;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 13px;
    }
  }
}
