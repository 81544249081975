@import "../../../../../assets/style/colors";
@import "../../../../../assets/style/breakpoints";

.close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.50vmin;
  width: 2.50vmin;
  border: 0.09vmin solid $color-grey-lightest;
  border-radius: 2vmin;
  transition-duration: 0.3s;

  @media (max-width: $breakpoint-md) {
    height: 27px;
    width: 27px;
    border-width: 1px;
    border-radius: 20px;
  }
  @media (max-width: $breakpoint-xs) {
    height: 20px;
    width: 20px;
  }

  &:hover {
    cursor: pointer;
    border-color: $color-grey;
    transition-duration: 0.3s;

    .close-button__icon {
      color: $color-grey;
      transition-duration: 0.3s;
    }
  }

  &.-disabled {
    background-color: $color-grey-light;
    pointer-events: none;

    .close-button__icon {
      color: $color-white;
    }
  }

  &__icon {
    color: $color-grey-lightest;
    height: 1.48vmin;
    transition-duration: 0.3s;

    @media (max-width: $breakpoint-md) {
      height: 16px;
    }
    @media (max-width: $breakpoint-xs) {
      height: 13px;
    }
  }
}
