@import "../../../assets/style/colors";
@import "../../../assets/style/breakpoints";

.main-menu-page {
  height: 100vh;
  position: relative;

  @media (max-width: $breakpoint-md) {
    height: var(--doc-height);
    padding: 0;
  }

  &__background-layer {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -2;
    background-color: $new-color-primary;
  }

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    position: relative;

    @media (max-width: $breakpoint-md) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-color: $new-color-primary;
    object-fit: cover;
  }

  &__content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-left: 13.89vmin;
    padding-right: 8.34vmin;
    width: 100%;

    @media (max-width: $breakpoint-lg) {
      padding: 2.78vmin;
    }
    @media (max-width: $breakpoint-md) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      height: calc(100% - 97px);
      margin: 0;
      padding: 20px 40px;
      overflow: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  &__title {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 3.15vmin;

    &-front {
      color: $color-white;
      font-size: 12.95vmin;
      line-height: 100%;
      font-weight: 900;
      text-transform: uppercase;

      @media (max-width: $breakpoint-lg) {
        font-size: 6.94vmin;
      }
    }

    &-back {
      color: $color-white;
      font-size: 12.95vmin;
      line-height: 100%;
      font-weight: 900;
      text-transform: uppercase;

      @media (max-width: $breakpoint-lg) {
        font-size: 6.94vmin;
      }
    }
  }

  &__descriptions {
    text-align: left;
    max-width: 55vmin;

    @media (max-width: $breakpoint-lg) {
      max-width: 37vmin;
    }
  }

  &__description {
    color: $color-white;
    font-size: 1.85vmin;
    line-height: 165%;
    font-weight: 500;
    margin-bottom: 2.85vmin;
  }

  &__menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: $breakpoint-md) {
      width: 100%;
      height: 100%;
      justify-content: space-between;
    }

    &-button {
      &:not(:last-child) {
        margin-bottom: 1.20vmin;

        @media (max-width: $breakpoint-md) {
          margin-bottom: 10px;
        }
      }
    }
  }

  &__bottom-section {
    position: relative;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: $new-color-primary;
    width: 100%;
    height: 47px;
    min-height: 47px;
  }
}
