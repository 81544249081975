@import "../../../assets/style/colors";

.profile-page {
  height: 100vh;
  max-width: 177.78vmin;

  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__mobile {
    background: $color-grey-lightest;
    flex-grow: 1;
  }
}
