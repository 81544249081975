@import "../../../../assets/style/colors";
@import "../../../../assets/style/breakpoints";

.my-candidate-page-languages {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 26.30vmin;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: $breakpoint-lg) {
    padding: 0 2.78vmin;
  }
  @media (max-width: $breakpoint-md) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1 0 auto;
    width: 100%;
    height: calc(100% - 146px - 60px);
    padding: 0 20px;
  }
  @media (max-width: $breakpoint-sm) {
    padding: 0 15px;
  }

  &__data-container-mobile {
    display: none;

    @media (max-width: $breakpoint-md) {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 40px;
      margin: 10px 0 20px;
    }
  }

  &__data-mobile {
    display: none;

    @media (max-width: $breakpoint-md) {
      display: block;
      color: $color-white;
      font-size: 13px;
      line-height: 180%;
      font-weight: 600;
    }
  }

  &__title {
    color: $color-white;
    font-size: 5.93vmin;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 1.30vmin;
    text-align: center;
    position: relative;

    @media (max-width: $breakpoint-md) {
      font-size: 30px;
      margin: 80px 0 15px;
    }
  }

  &__icon {
    position: absolute;
    bottom: calc(100% + 2.69vmin);
    left: 50%;
    transform: translateX(-50%);
    height: 12.78vmin;
    width: 14.26vmin;
    background-image: url("/assets/images/icons/icon-languages.svg");
    background-size: contain;
    background-repeat: no-repeat;

    @media (max-width: $breakpoint-md) {
      bottom: calc(100% + 20px);
      height: 60px;
      width: 67px;
    }
  }

  &__description {
    color: $color-white;
    font-size: 1.48;
    margin-bottom: 5vmin;
    text-align: center;

    @media (max-width: $breakpoint-md) {
      font-size: 15px;
      line-height: 150%;
      margin-bottom: 20px;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: $breakpoint-md) {
      width: 100%;
    }

    &-textarea {
      margin-bottom: 5.37vmin;

      @media (max-width: $breakpoint-md) {
        margin-bottom: 20px;
      }
    }
  }

  &__level-description {
    color: $color-white;
    font-size: 1.39vmin;
    margin-bottom: 2.31vmin;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;

    @media (max-width: $breakpoint-md) {
      font-size: 13px;
      margin-bottom: 10px;
    }
  }

  &__level-selector {
    margin-bottom: 8.33vmin;

    @media (max-width: $breakpoint-md) {
      margin-bottom: 20px;
    }
  }

  &__button-container {
    display: flex;
    flex-direction: row;

    @media (max-width: $breakpoint-md) {
      justify-content: space-around;
      width: 100%;
      margin-bottom: 40px;
    }
    @media (max-width: $breakpoint-sm) {
      justify-content: space-between;
      width: 100%;
    }
  }

  &__button {
    width: 18.80vmin;

    @media (max-width: $breakpoint-md) {
      width: 30%;
    }
    @media (max-width: $breakpoint-sm) {
      font-size: 11px;
      width: 48%;
    }

    &:not(:last-child) {
      margin-right: 1.30vmin;

      @media (max-width: $breakpoint-md) {
        margin: 0;
      }
    }
  }
}
