@import "../../../assets/style/colors";

.video-modal {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: $color-black;
  animation:fadein .5s;

  @keyframes fadein {
    from { opacity: 0 }
    to { opacity: 1 }
  }

  @-webkit-keyframes fadein {
    from { opacity: 0 }
    to { opacity: 1 }
  }

  &.-hidden {
    display: none;
  }

  &__video {
    width: 100%;
    height: 100%;
  }

  &__play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $color-secondary;
    font-size: 10vmin;
    animation:fadein .5s;
  
    @keyframes fadein {
      from { opacity: 0 }
      to { opacity: 1 }
    }
  
    @-webkit-keyframes fadein {
      from { opacity: 0 }
      to { opacity: 1 }
    }

    &.-hidden {
      display: none;
    }
  }

  &__close-button {
    position: absolute;
    top: 2.45vmin;
    right: 2.45vmin;
  }
}
