.case-page-desktop-result {
  display: flex;
  flex-direction: row;
  width: 78%;

  &__content {
    width: 33%;
    margin-right: 5%;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__title {
    font-size: 3.24vmin;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 2.96vmin;
  }

  &__number {
    font-size: 1.11vmin;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 2.22vmin;
  }

  &__button {
    margin-bottom: 2.5vmin;
  }

  &__description {
    font-size: 1.39vmin;
    line-height: 2.31vmin;
  }

  &__gallery {
    width: 61%;
    height: 100%;
  }

  &__slider {
    height: 100%;

    .slick-list {
      height: 100%;
    }

    .slick-track {
      height: 100%;
    }

    .slick-slide {
      height: 100%;

      div {
        height: 100%;
      }
    }
  }

  &__image-container {
    overflow: hidden;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
